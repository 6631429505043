import { createSlice } from "@reduxjs/toolkit";

interface InitialCategoryState {
  category: any;
  planCatList: any;
  cat_rowCount: number;
  met_rowCount: number;
  categoryById: any;
  calMeetings: any;
  meetingsById: any;
  subCategoryById: any;
  isLoading: boolean;
  permission: any;
  newuserdata: any;
  MembershipData: any;
  meetings: any;
  member_rowCount: number;
}

const InitialCategoryState: InitialCategoryState = {
  category: [],
  meetings: [],
  isLoading: true,
  planCatList: [],
  calMeetings: [],
  cat_rowCount: 0,
  met_rowCount: 0,
  categoryById: {},
  meetingsById: {},
  subCategoryById: {},
  permission: {},
  newuserdata: {},
  MembershipData: {},
  member_rowCount: 0
};

const categorySlice = createSlice({
  name: "category",
  initialState: InitialCategoryState,
  reducers: {
    fetchCategory(state: any, action: any) {
      state.category = action.payload;
    },
    fetchMeetings(state: any, action: any) {
      state.meetings = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    addCategory(state: any, action: any) {
      if (state?.category) {
        state.category.unshift(action.payload);
        if (state.category.length > 10) {
          state.category.pop();
        }
      } else {
        state.category = action.payload;
      }
      state.cat_rowCount = Number(state.cat_rowCount) + 1;
    },
    addMeetings(state: any, action: any) {
      if (state?.meetings) {
        state.meetings.unshift(action.payload);
        if (state.meetings.length > 10) {
          state.meetings.pop();
        }
      } else {
        state.meetings = action.payload;
      }
      state.met_rowCount = Number(state.met_rowCount) + 1;
    },
    clearMeetings: (state: any) => {
      state.meetingsById = {};
    },
    setCatRowCount(state: any, action: any) {
      state.cat_rowCount = action.payload;
    },
    setMetRowCount(state: any, action: any) {
      state.met_rowCount = action.payload;
    },
    deleteCategory(state: any, action: any) {
      state.category = state.category.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    editCategory(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.category.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.category[objIndex] = {
          ...state.category[objIndex],
          ...updatedData,
        };
      }
    },
    editMeetings(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.meetings.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.meetings[objIndex] = {
          ...state.meetings[objIndex],
          ...updatedData,
        };
      }
    },
    fetchlevel(state: any, action: any) {
      state.level = action.payload;
    },
    addLevel(state: any, action: any) {
      state?.level?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    editLevel(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.level.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.level[objIndex] = {
          ...state.level[objIndex],
          ...updatedData,
        };
      }
    },
    editLevelColor(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.level.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.level[objIndex] = {
          ...state.level[objIndex],
          ...updatedData,
        };
      }
    },
    deleteLevel(state: any, action: any) {
      state.level = state.level.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setCategoryById(state: any, action: any) {
      state.categoryById = action.payload;
    },
    setMeetingsById(state: any, action: any) {
      state.meetingsById = action.payload;
    },
    setSubCategoryById(state: any, action: any) {
      state.subCategoryById = action.payload;
    },
    clearcat: (state: any) => {
      state.categoryById = {};
    },
    clearSubcat: (state: any) => {
      state.subCategoryById = {};
    },
    getCatListData(state: any, action: any) {
      state.planCatList = action.payload;
    },
    getLevelPermission(state: any, action: any) {
      state.permission = action.payload;
    },
    updateLevelPermission(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    changeLevelPromoStatus(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    changeLevelAutoApproved(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    newUserData(state: any, action: any) {
      state.newuserdata = action.payload;
    },
    getMembershipSchool(state: any, action: any) {
      state.MembershipData = action.payload;
    },
    deleteMembershipSchool(state: any, action: any) {
      state.MembershipData = state.MembershipData.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setMember_rowCount(state: any, action: any) {
      state.member_rowCount = action.payload;
    },
    fetchMeetingsForCal(state: any, action: any) {
      state.calMeetings = action.payload;
    },
    cancelMeet(state: any, action: any) {
      // state.meetings = action.payload;

      const { _id } = action.payload;

      state.meetings = state.meetings.map((meets: any) =>
        meets._id === _id ? { ...meets, status: 3 } : meets
      );
    },
  },
});

export const {
  fetchCategory,
  setLoading,
  deleteCategory,
  addCategory,
  addMeetings,
  addLevel,
  editCategory,
  editMeetings,
  fetchlevel,
  editLevel,
  editLevelColor,
  deleteLevel,
  setCatRowCount,
  setCategoryById,
  setMeetingsById,
  setSubCategoryById,
  clearcat,
  clearSubcat,
  getCatListData,
  getLevelPermission,
  updateLevelPermission,
  changeLevelPromoStatus,
  changeLevelAutoApproved,
  newUserData,
  getMembershipSchool,
  deleteMembershipSchool,
  fetchMeetings,
  clearMeetings,
  setMetRowCount,
  setMember_rowCount,
  fetchMeetingsForCal,
  cancelMeet,
}: any = categorySlice.actions;

export default categorySlice.reducer;

export const category_list = (state: any) => state.category.category;

export const meetings_list = (state: any) => state.category.meetings;

export const categoryLoading = (state: any) => state.category.category;

export const level_list = (state: any) => state.category.level;

export const plan_cat_list = (state: any) => state.category.planCatList;

export const cat_rowCount = (state: any) => state.category.cat_rowCount;

export const met_rowCount = (state: any) => state.category.met_rowCount;

export const categoryById = (state: any) => state.category.categoryById;

export const subCategoryById = (state: any) => state.category.subCategoryById;

export const subMeetingsById = (state: any) => state.category.meetingsById;

export const getLevelPer_ById = (state: any) => state.category.permission;

export const new_user_data = (state: any) => state.category.newuserdata;

export const MembershipSchoolData = (state: any) => state.category.MembershipData;

export const member_rowCount = (state: any) => state.category.member_rowCount;

export const calMeetingsList = (state: any) => state?.category?.calMeetings;
