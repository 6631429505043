import { createSlice } from "@reduxjs/toolkit";

interface InitialInvoiceState {
  Invoices: any;
  
}

const InitialInvoiceState: InitialInvoiceState = {
Invoices: [],
 
};

const InVoiceSlice = createSlice({
  name: "Invoice",
  initialState: InitialInvoiceState,
  reducers: {
    fetchInvoice(state: any, action: any) {
      state.Invoices = action.payload;
    },
   
  },
});

export const {
    fetchInvoice,
  
} = InVoiceSlice.actions;

export default InVoiceSlice.reducer;

export const InVoice_list = (state: any) => state.Invoice.Invoices;

