// React Imports
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useLocation, useNavigate } from "react-router-dom";

// Icon
import FeatherIcon from "feather-icons-react";

// Bootstrap Imports
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

// Helper
import AppUtils from "../../../../Helpers/AppUtils";
import ImageHelper from "../../../../Helpers/Images/Image";

// Style Imports
import "./Header.scss";
import UrlHelper from "../../../../Helpers/Url";

export default function Header(props: any) {

  const { testimonialsRef } = props;

  // State
  const [scroll, setScroll] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");
  const localData = AppUtils.getLocalStorage("ISSO")
  const previousPage = location.pathname


  useEffect(() => {
    if (scroll && testimonialsRef.current) {
      setTimeout(() => {
        testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
        setScroll(false);
      }, 200);
    }
  }, [scroll, location]);

  useEffect(() => {
    const { state } = location;
    if (state?.scrollToTestimonials && testimonialsRef.current) {
      setScroll(true);
    }
  }, [location]);

  const handleScrollToSection = () => {
    if (location.pathname !== "/home-page") {
      navigate("/home-page", { state: { scrollToTestimonials: true } });
    } else if (testimonialsRef.current) {
      testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1rem",
          fontFamily: "Bai Jamjuree",
        }}
      >
        <div className="HederContentBox">
          <img
            style={{ marginRight: "2rem", height: "95px", width: "95px", cursor: "pointer "}}
            src={ImageHelper.ISSOLogo}
            alt={UrlHelper.serverPlatForm=="WSF" ? ImageHelper.WSFLogo :ImageHelper.ISSOLogoPlaceholder}
            onClick={() => {
              navigate("/home-page");
            }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            {(!isTabletOrMobile || (isTabletOrMobile && showNav)) && (
              <nav>
                <ul style={{ display: "flex" }}>
                  <li>
                    <a href="/home-page" className="headNav">
                      ISSO'S edge
                    </a>
                  </li>
                  <li>
                    <a href="/home-page/calendar" className="headNav">
                      Calendar
                    </a>
                  </li>
                  <li>
                    <a href="/home-page/school-view" className="headNav">
                      Schools
                    </a>
                  </li>
                  <li>
                    <a href="/home-page/past-events" className="headNav">
                      Medal Tally
                    </a>
                  </li>
                  <li>
                    <a id="testimonials" className="headNav pointer" onClick={handleScrollToSection}>
                      Testimonials
                    </a>
                  </li>
                  <li>
                    <a href="/home-page/faq-page" className="headNav">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/home-page/contact-us" className="headNav">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            )}
            {isTabletOrMobile && (
              <div id="goToDash">
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => { setDropdownOpen(!dropdownOpen) }}
                >
                  <DropdownToggle color="" className="menuIcon">
                    <FeatherIcon className="icon" icon="menu" size={24} />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {isTabletOrMobile && !localData ? (
                      <DropdownItem>
                        <button
                          className="memLoginBtn"
                          onClick={() => {
                            AppUtils.setLocalStorage("previousPage", previousPage);
                            if ((window.location.origin === UrlHelper.webServerUrl) || (window.location.origin === UrlHelper.membershipserverUrl)) {
                              window.location.replace(`${UrlHelper.memberserverUrl}/login`);
                            }
                            else {
                              navigate("/login");
                            }
                          }}
                        >
                          Member Login
                        </button>
                      </DropdownItem>
                    ) : (
                      isTabletOrMobile && localData && (
                        <DropdownItem>
                          <button
                            className="memLoginBtn"
                            onClick={() => {
                              navigate("/dashboard");
                            }}
                          >
                            Go to Dashboard
                          </button>
                        </DropdownItem>
                      )
                    )}
                    <DropdownItem>
                      <a href="/home-page" className="dropdownName">
                        ISSO'S EDGE
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/home-page/calendar" className="dropdownName">
                        CALENDAR
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/home-page/school-view" className="dropdownName">
                        SCHOOLS
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/home-page/past-events" className="dropdownName">
                        MEDAL TALLY
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        href="/home-page#testimonials"
                        className="dropdownName"
                      >
                        TESTIMONIALS
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/home-page/past-events" className="dropdownName">
                        GALLERY
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/home-page/contact-us" className="dropdownName">
                        CONTACT US
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
          {!isTabletOrMobile && !localData ? (
            <button
              className="memLoginBtn"
              onClick={() => {
                AppUtils.setLocalStorage("previousPage", previousPage);
                if ((window.location.origin === UrlHelper.webServerUrl) || (window.location.origin === UrlHelper.membershipserverUrl)) {
                  window.location.replace(`${UrlHelper.memberserverUrl}/login`);
                }
                else {
                  navigate("/login");
                }
              }}
            >
              Member Login
            </button>
          ) : (
            !isTabletOrMobile && localData && (
              <button
                className="memLoginBtn"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Go to Dashboard
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
}
