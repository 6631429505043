// ** React Imports
import { Suspense, useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useNavigate } from "react-router-dom";

// ** Third party Imports
import { Image } from "../../../AbstractElements";

// ** Custom Component
import Subscribeotp from "./Subscribeotp.component";
import Testimonialpopup from "./Testimonialpopup";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  addNewsSubscribeData,
  sendVerificationCodeData,
} from "../../../ReduxToolkit/Reducer/Footer/FooterAction";
import { getFooterData } from "../../../ReduxToolkit/Reducer/HomePage/HomePageAction";
import { footer_Data } from "../../../ReduxToolkit/Reducer/HomePage/HomePageSlice";

// ** Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Helper
import ImageHelper from "../../../Helpers/Images/Image";

//Styles Imports
// import "./Footer.scss";
import AppUtils from "../../../Helpers/AppUtils";
import { emailValidation, invalidEmail } from "../../../utils/Constant";

interface EventData {
  email: string;
}

const defaultValues: EventData = {
  email: "",
};

function Footer() {
  // State 123
  const [subscribeData, setSubscribeData] = useState();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isTestimonialVisible, setTestimonialsVisible] = useState(false);

  //Hooks
  const dispatch = useDispatch();
  const footerData = useSelector(footer_Data);
  const isMobileView = useMediaQuery("(max-width: 1024px)");
  const navigate = useNavigate();

  useEffect(() => {
    fetchListData();
  }, []);

  const fetchListData = async () => {
    try {
      await getFooterData({}, dispatch);
    } catch (error) {
      console.error("Error fetching list data:", error);
    }
  };

  const verificationCode = async (id: any) => {
    try {
      await sendVerificationCodeData({ id: id }, dispatch);
    } catch (error) {
      console.error("Error fetching list data:", error);
    }
  };

  // Fomik Validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(invalidEmail).required(emailValidation),
  });

  // Fomik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: async (values: EventData) => {
      const passData: any = {
        email: values.email,
      };
      const res = await addNewsSubscribeData(passData, dispatch);
      if (res?.status) {
        setSubscribeData(res?.id);

        if (!res?.is_verify_code) {
          verificationCode(res?.id);
          setPopupVisible(true);
        }
      } else {
        console.error("Subscription failed");
      }
    },
  });

  const handleMapClick = (location: any) => {
    console.log("location:::", location)
    const googleMapsUrl = `https://www.google.com/maps?q=${location},${location}`;
    const newWindow = window.open(googleMapsUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  

  const Location = { lat: 40.51864, lng: -80.02461 };

  return (
    <>
      <div className="footerMain">
        <div
          className="footer-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "1rem",
          }}
        >
          <div className="resBox">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: "1rem" }}>
                <Image
                  className="img-fluid"
                  src={ImageHelper.ISSOLogo}
                  alt="logo"
                  style={{ height: "57px",cursor: "pointer "  }}
                />
              </div>
              <p
                style={{
                  fontFamily: "Fight Night",
                  fontSize: "26px",
                  fontWeight: "800",
                  lineHeight: "33px",
                  letterSpacing: "0.5px",
                  color: "#FFFFFF",
                  zIndex: 9,
                }}
              >
                {footerData?.title}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <a
                  href={footerData?.fb_link}
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img src={ImageHelper.facebookIcon} alt="facebook" />
                </a>
                <a
                  href={footerData?.youtube_link}
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img src={ImageHelper.youtube} alt="youtube" style={{ width: '1.4rem', marginTop: '0.1rem' }} />
                </a>
                <a
                  href={footerData?.instagram_link}
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ImageHelper.instagramIcon}
                    alt="instagram"
                  />
                </a>
                <a
                  href={footerData?.twitter_link}
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <img src={ImageHelper.twitterIcon} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
          <div className="resBox">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <div
                  style={{ cursor: "pointer" }}
                  // onClick={() => handleMapClick(Location)}
                  onClick={() => footerData?.locations?.length > 0 && handleMapClick(footerData?.locations[0]?.location)}
                >
                  <Image
                    src={ImageHelper.map}
                    style={{ width: "25px" }}
                    alt="twitter"
                  />
                </div>
                <div>
                  {footerData &&
                    footerData?.locations?.length > 0 &&
                    footerData?.locations?.map((v: any, index: any) => (
                      <p
                        className="addredesc"
                        style={{ marginBottom: "0px" }}
                        key={index}
                      >
                        <span className="addresstitle">{v.location_title}</span>
                        <p className="addredesc">{v.location}</p>
                      </p>
                    ))}
                </div>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <a href={`mailto:${footerData?.email_id?.[0]}`}>
                  <Image
                    src={ImageHelper.inbox}
                    style={{ width: "25px" }}
                    alt="twitter"
                  />
                </a>
                <div>
                  <span className="addresstitle">
                    {footerData?.email_id_title}
                  </span>
                  {footerData &&
                    footerData?.email_id?.length > 0 &&
                    footerData?.email_id?.map((v: any, index: any) => (
                      <p
                        className="addredesc"
                        style={{ marginBottom: "0px" }}
                        key={index}
                      >
                        {v}
                      </p>
                    ))}
                </div>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <a
                  href={`tel:+${footerData.phone_no?.[0]?.country_code}${footerData.phone_no?.[0]?.mobile_no}`}
                >
                  <Image
                    className="img-fluid"
                    src={ImageHelper.call}
                    style={{ width: "25px" }}
                    alt="twitter"
                  />
                </a>
                <div>
                  {footerData?.phone_no?.length > 0 &&
                    <>
                      <span className="addresstitle">Phone</span>
                      {footerData?.phone_no?.map((v: any, index: any) => (
                        <p
                          className="addredesc"
                          style={{ marginBottom: "0px" }}
                          key={index}
                        >
                          +{v.country_code} {v.mobile_no}
                        </p>
                      ))}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            className="resBox"
            style={{ marginLeft: isMobileView ? "" : "7rem" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <p className="footerlinks">About</p>
              <a href="/home-page" className="foteraboutlinks">
                ISSO’S edge
              </a>
              <a href="/home-page/calendar" className="foteraboutlinks">
                Calendar
              </a>
              <a href="#testimonials" className="foteraboutlinks pointer" onClick={(e) => {
                if (AppUtils.getLocalStorage("ISSO")) {
                  e.preventDefault();
                  setTestimonialsVisible(true)
                }
              }}>
                Testimonials
              </a>
              <a href="/home-page/past-events" className="foteraboutlinks">
                Gallery
              </a>
              <a href="/home-page/contact-us" className="foteraboutlinks">
                Contact Us
              </a>
              <a href="/home-page/privacy-policy" className="foteraboutlinks">
                Privacy policy
              </a>
              <a href="/home-page/faq-page" className="foteraboutlinks">
                FAQ
              </a>
            </div>
          </div>
          <div className="resBox">
            <p
              style={{
                fontFamily: "Fight Night",
                color: "white",
                fontSize: "18px",
                fontWeight: "400",
                letterSpacing: "0.5px",
                marginLeft: "0.3rem",
              }}
            >
              Join Newsletter
            </p>
            <div className="newsletter">
              <input
                type="email"
                placeholder="Type email here"
                className="emailinput"
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">
                  {formik.touched.email && formik.errors.email}
                </div>
              ) : null}
              <button
                className="subscribe"
                onClick={() => formik.handleSubmit()}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div className="ShapeL">
          <Image className="img-fluid" src={ImageHelper.FooterImgLeft} alt="logo" />
        </div>
        <div className="ShapeR">
          <Image className="img-fluid" src={ImageHelper.FooterRight} alt="logo" />
        </div>
      </div >
      <div className="rights" style={{ background: "black", color: "white", borderTop: isMobileView ? "1px solid white" : "" }}>
        <span
          style={{
            fontFamily: "Jost-Regular",
            fontSize: "12px",
            padding: "1rem",
          }}
        >
          © All rights reserved isso
        </span>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Jost-Regular",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/home-page/privacy-policy");
            }}
          >
            Privacy Policy
          </span>
          {isMobileView && <span style={{ fontSize: "12px" }}>|</span>}
          <span
            style={{
              fontFamily: "Jost-Regular",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/home-page/terms-and-conditions");
            }}
          >
            Terms & Conditions
          </span>
        </div>
      </div>
      {
        isPopupVisible && (
          <Suspense>
            <Subscribeotp
              setPopupVisible={setPopupVisible}
              isPopupVisible={isPopupVisible}
              subscribeData={subscribeData}
              toggleModal={() => {
                setPopupVisible(!isPopupVisible);
              }}
            />
          </Suspense>
        )
      }

      {isTestimonialVisible && (
        <Suspense>
          <Testimonialpopup
            setTestimonialsVisible={setTestimonialsVisible}
            isTestimonialVisible={isTestimonialVisible}
            toggleModal={() => {
              setTestimonialsVisible(!isTestimonialVisible);
            }}
          />
        </Suspense>
      )}
    </>
  );
}

export default Footer;
