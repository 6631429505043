import ColorsHelper from "../Helpers/Colors";

export class ConfigDB {
    static data = {
      settings: {
        layout_type: 'ltr',
        layout_class: 'compact-wrapper',
        sidebar: {
          iconType: 'stroke',
        },
        sidebar_setting: 'default-sidebar',
      },
      color: {
        primary_color: ColorsHelper.defClr,
        secondary_color: ColorsHelper.secClr,
        mix_background_layout: 'light-only',
      }
    };
  }
  export default ConfigDB;