// Created By Yash l.

// ** Service Imports
import apiCall from "../../../Helpers/Services";

import { fetchEarnings, fetchPayHistory, rejectPayment, setEarningsRowCount, setPayHistoryRowCount, approvalPayment, fetchEarningSummary, userPoints, fetchUsedEarnings } from "./MyEarningsSlice";

export const myEarningsList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/getUserEarning",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchEarnings(data?.data));
    dispatch(setEarningsRowCount(data?.record_count));
  }
};

export const myEarningsUsedList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/pointsUsedHistory",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchUsedEarnings(data?.data));
    dispatch(setEarningsRowCount(data?.record_count));
  }
};

export const paymentHisList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/userPaymentHistory",
    data: passData,
  });

  if (data?.status) {
    dispatch(fetchPayHistory(data?.data));
    dispatch(setPayHistoryRowCount(data?.record_count));
  }
};

export const getEarningSummary = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/getEarningSummary",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchEarningSummary(data?.data));
  }
};

export const rejectPaymentStatus = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/rejectPayment",
    data: passData,
    isHideTostMsg: false,
  });

  if (data?.status) {
    dispatch(rejectPayment({ _id: passData.id }));
  }

  return data;
};

export const addWithdrawRequest = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/addWithdrawRequest",
    data: passData,
    isMultipart: false,
  });

  // data?.status && dispatch(addWithdrawReq({ ...passData, _id: data?.id }));

  return data;
};

export const updatePaymentStatus = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "user/updatePaymentStatus",
    data: passData,
    isMultipart: false,
  });

  data?.status && dispatch(approvalPayment({ _id: passData.id }));

  return data;
};

export const fetchUserPoints = async (passData: any, dispatch: any) => {
  
  const response = await apiCall({
    method: "POST",
    url: "user/getUserPoints",
    data: passData,
    isMultipart: false,
  });
  console.log(response)

  if (response?.status) {
    // Dispatch the userpoints action with the payload containing pointsRemaining
    dispatch(userPoints(response.data));
  }

  return response;
};