import apiCall from "../../../Helpers/Services";

export const registerUser = async (passData: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "registration",
    data: passData,
  });

  return data;
};

export const registerEvent = async (passData: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "registration/userRegistration",
    data: passData,
  });

  return data;
};
