import { io } from "socket.io-client";

// Helper
import AppUtils from "../Helpers/AppUtils";
import UrlHelper from "../Helpers/Url";

const socket = io(UrlHelper.frontendServerUrl, {
  path: "/socket.io",
  query: {
    userId: AppUtils.getUserDtls()?.data?.id ?? "",
  },
  transports: ["websocket"],
});

socket.on("connect", () => {
  console.log("Connected to server");
});

socket.on("disconnect", () => {
  console.log("Disconnected from server");
});

socket.on("connect_error", (err: any) => {
  console.error("Connection error:", err);
});

export default socket;
