import apiCall from "../../../Helpers/Services";
import { fetchInvoice } from "./InvoiceSlice";

export const InvoiceData = async (passData: any, dispatch: any) => {
    const data: any = await apiCall({
      method: "GET",
      url: "user/findInvoiceConfig",
      data: passData,
      isHideTostMsg: true,
    });
    
    if (data?.status) {
      dispatch(fetchInvoice(data?.data));
    }
    
  
    return data;
  };

  export const EditInvoiceConfig = async (passData: any, dispatch: any) => {
    const data: any = await apiCall({
      method: "POST",
      url: "user/addOrEditInvoiceConfig",
      data: passData,
      isMultipart:true
    });
  
    if (data?.status) {
      dispatch(fetchInvoice(data?.data));
    }
    return data;
  };