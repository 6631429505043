import apiCall from "../../../Helpers/Services";
import {
  addPopularData,
  fetchPopularEvents,
  popularEveLists,
} from "./PopularEventsSlice";

export const addPopularEvents = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "userevent/addPopularEvent",
    data: passData,
  });

  data?.status && dispatch(addPopularData({ ...passData, _id: data?.id }));

  return data;
};

export const getPopularEventsLists = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "GET",
    url: "userevent/getPopularEvent",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(popularEveLists(data?.data));
  }
  return data;
};

export const getIsPopularEventsLists = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "userevent/fetchPopularEvent",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchPopularEvents(data?.data));
  }
  return data;
};
