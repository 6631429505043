// React Imports
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useLocation, useNavigate } from "react-router-dom";
import NavLinksModal from "./NavLinksModal";

// Icon
import FeatherIcon from "feather-icons-react";
import { RiArrowDropDownLine } from "react-icons/ri";


// Bootstrap Imports
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

// Helper
import UrlHelper from "../../../../Helpers/Url";
import AppUtils from "../../../../Helpers/AppUtils";
import ImageHelper from "../../../../Helpers/Images/Image";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiMenu } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";

// Style Imports
import "./WSFHeader.scss"
import { FaUser } from "react-icons/fa";

interface Link {
  label: string;
  href: string;
}

export default function WSFHeader(props: any) {

  const { testimonialsRef } = props;
  const WSFBaseNavigationUrl = `${UrlHelper.webServerUrl}/`

  // State
  const [scroll, setScroll] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");
  const localData = AppUtils.getLocalStorage("ISSO")
  const previousPage = location.pathname


  useEffect(() => {
    if (scroll && testimonialsRef.current) {
      setTimeout(() => {
        testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
        setScroll(false);
      }, 200);
    }
  }, [scroll, location]);

  useEffect(() => {
    const { state } = location;
    if (state?.scrollToTestimonials && testimonialsRef.current) {
      setScroll(true);
    }
  }, [location]);

  const handleScrollToSection = () => {
    if (location.pathname !== "/home-page") {
      navigate("/home-page", { state: { scrollToTestimonials: true } });
    } else if (testimonialsRef.current) {
      testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const links : Link[] = [
    { label: 'Home', href: `${UrlHelper.webServerUrl}/` },
    { label: 'WSF Foundation', href: `${UrlHelper.webServerUrl}/wsf-foundation/` },
    { label: 'Research & Advocacy', href: `${UrlHelper.webServerUrl}/research-advocacy/` },
  ];

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.querySelectorAll<HTMLAnchorElement>('a').forEach((a) => {
      a.style.transform = 'translateY(-25px)';
    });
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.querySelectorAll<HTMLAnchorElement>('a').forEach((a) => {
      a.style.transform = 'translateY(0px)';
    });
  };

  const navLinks = (link: Link, index: number) => {
    return (
      <div
        className="d-flex flex-column align-items-center px-2"
        style={{ height: '1.8rem', overflow: 'hidden' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <a
          key={index}
          href={link.href}
          style={{
            fontSize: '16px',
            color: '#1F2022',
            transition: 'all 0.3s ease-in-out',
            fontWeight:500,
          }}
        >
          {link.label}
        </a>
        <a
          key={index}
          href={link.href}
          style={{
            fontSize: '16px',
            color: '#1F2022',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          {link.label}
        </a>
      </div>
    )
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
          ...AppUtils.getFontFamily(),
          padding:"1rem 3.2rem"
        }}
      >
       
        <div>
          <a href={`${WSFBaseNavigationUrl}`}>
          <img
            style={{ marginRight: "2rem", height: "64px", width: "256px", cursor: "pointer "}}
            src={ImageHelper.WSFLogo}
            alt={ImageHelper.ISSOLogoPlaceholder}
          />
          </a>
          </div>
          <div style={{width:"553px"}}>
            {(!isTabletOrMobile || (isTabletOrMobile && showNav)) && (
             <div>
             <nav className="d-flex align-items-center gap-4">
              {
                Array.isArray(links) && links?.length > 0 &&
                links?.map((link:Link, index:number) => navLinks(link, index))
              }
             </nav>
           </div>
            )}
            {isTabletOrMobile && (
              <div id="goToDash" className="text-end">
                <FiMenu onClick={() => setIsOpen(true)} size={35} style={{ cursor: "pointer" }} />
              </div>
            )}
          </div>
          <div>
          {!isTabletOrMobile && !localData ? (
            <button
              className="memLoginBtn"
              onClick={() => {
                AppUtils.setLocalStorage("previousPage", previousPage);
                if ((window.location.origin === UrlHelper.webServerUrl) || (window.location.origin === UrlHelper.membershipserverUrl)) {
                  window.location.replace(`${UrlHelper.memberserverUrl}/login`);
                }
                else {
                  navigate("/login");
                }
              }}
            >
              Member Login
            </button>
          ) : (
            !isTabletOrMobile && localData && (
                <div style={{display:"flex",alignItems:"center",gap:"28px"}}>
                  <FaUser style={{ cursor: "pointer" }} size={25} />
                <CiSearch style={{width:"30px",height:"30px"}} className="d-none" />
                <GiHamburgerMenu style={{width:"30px",height:"30px"}} className="d-none"/>

            </div>
            )
          )}
          </div>
        
      </div>

      <NavLinksModal open={isOpen} toggle={() => setIsOpen(false)}/>
   </>
  );
}
