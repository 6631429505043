import UrlHelper from "../../../../Helpers/Url";
import WSFHeader from "../WSFHeader/WSFHeader";
import Header from "../Header/Header";

const DynamicHeader=()=>{
    return(
        <>
        {UrlHelper.serverPlatForm=="WSF" ? <WSFHeader/>:<Header />}
        </>

    )
}
export default  DynamicHeader;