const issoColor =  {
  defClr: "#006666",
  subBtnClr: "#006666",
  secClr: "#FE6A49",
  txtBlack: "#2f2b3d",
  borderClr: "#006666",
  bgPrime: "#006666",
  txtPrime: "#006666",
  linearGradientClr:"#006666"
}

const wsfColor =  {
  defClr: "#255DAB",
  subBtnClr: "#255DAB",
  secClr: "#d5376f",
  txtBlack: "#2f2b3d",
  borderClr: "#255DAB",
  bgPrime: "#255DAB",
  txtPrime: "#255DAB",
  linearGradientClr:"linear-gradient(90deg, #255dab 0%, #2958a8 11%, #374ca0 23%, #4e3993 36%, #5c2d8c 42%, #e81d6f 92%)"
}

const ColorsHelper = {
  whiteClr: "#FFFFFF",
  bgWhiteClr: "#FFFFFF",
  whiteRgbClr: "rgb(255, 255, 255)",
  whiteRgb45Clr: "rgba(255, 255, 255, 0.45)",
  blackClr: "#000",
  boxShadowPurple: "#c7c7c7",
  Black: "#333333",
  black: "#2f2b3d",
  black10: "rgba(0, 0, 0, 0.1)",
  black60: "rgba(0, 0, 0, 0.6)",
  blackGray: "rgb(35, 32, 32)",
  darkGray: "#AAAAAA",
  lightGrayBlue: "#dee2e6",
  bgPurple: "#7367f0",//rgb(115, 103, 240)
  BgBlue: "#3853a3",
  bgBlue1: "#0F1221",
  txtGrey: "#ababab",
  txtRed: "#d32f2f",
  txtRed2: "#EE1D23",
  txtgrey: "#ababab",
  txtBlue: "#a8b5cd",
  txtGreen: "#5ABC77",
  boxShadowGrey: "#888888",
  boderGrey: "#d8d8d8",
  boderGrey1: "#d2cfcf",
  boderGrey2: "#cccccc",
  boderGrey3: "#d3d3d3",
  boderGrey4: "#d8d8d8",
  boderGrey5: "#6c757d",
  borderWht: "#E6E9EB",
  borderGreen: "#008000",
  borderBlack: "#000000",
  borderRed: "#ff775e",
  borderWhite:"#ecf3fa",
  whiteGray:"#F0F0F0",
  //dhruvam bhalodiya
  greenClr:"#2CBF0F",
  grayClr:'#B5B5B5',
  ...((process.env.REACT_APP_PLATFORM === 'WSF') ? wsfColor : issoColor)
};

export default ColorsHelper;
