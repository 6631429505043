export const CamelCase = (text: any) => {
  if (!text) return text; 

  return text
    .split(' ')
    .map((word: any) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1); 
      }
      return word; 
    })
    .join(' ');
};

export default CamelCase;

export const capitalizeFirstLetter = (text:any) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  };

  