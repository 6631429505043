// Created By MK

// Third Party Imports
import axios from "axios";
import { toast } from "react-toastify";

// Helper Imports
import AppUtils from "../AppUtils";
import UrlHelper from "../Url";

// Type Definitions
interface ApiCallData {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: any;
  token?: string;
  isMultipart?: boolean;
  isHideTostMsg?: boolean;
  isLogin?: boolean;
}

const apiCall = async (data: ApiCallData) => {
  let response;

  const localstoreData = AppUtils.getLocalStorage("ISSO");
  const ip = AppUtils.getPublicIp() || ""; // Use the stored IP address or fallback to an empty string

  const headers = {
    ...(data?.token || localstoreData?.token
      ? { Authorization: `Bearer ${data?.token || localstoreData?.token}` }
      : {}),
    ip,
    ...(data.isMultipart && { "Content-Type": "multipart/form-data" }),
  };

  try {
    response = await axios({
      method: data.method,
      url: `${UrlHelper.serverApiUrl}${data.url}`,
      headers,
      data: data.data,
    });

    if (response.status === 200 && response.data.status) {
      if (!data.isHideTostMsg) {
        toast.success(response.data.message);
      }
      return response.data;
    }
  } catch (error: any) {
    console.log(":::service catch::::", error);
    response = error.response;

    if (response?.status === 401) {
      AppUtils.removeLocalStorageItem("ISSO");
      window.location.href =  (window.location.origin === UrlHelper.membershipserverUrl) ? "/home-page" : "/login";
    } else if (response?.status === 403) {
      AppUtils.removeLocalStorageItem("ISSO");
      window.location.href = "/";
    } else {
      const errorMessage = response?.data?.message || "Something went wrong!";
      toast.error(errorMessage);
      return response?.data;
    }
  }

  return data.isLogin ? response?.data : response;
};

export default apiCall;
