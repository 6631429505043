// Create By Yash l.

// React Imports
import { useEffect, useState } from "react";

// Third Party Imports
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

// Helpers Imports
import UrlHelper from "../../Helpers/Url";

// Redux Imports
import { onPaymentAuth, onSuccessfulAuth } from "../../ReduxToolkit/Reducer/Auth/AuthSlice";

// Style Imports
import './PaymentGetway.scss'
import { getIsPopularEventsLists } from "../../ReduxToolkit/Reducer/PopularEvents/PopularEventsAction";
import { fetchUserPoints } from "../../ReduxToolkit/Reducer/MyEarnings/MyEarningsAction";
import { InvoiceData } from "../../ReduxToolkit/Reducer/InvoiceConfig/InvoiceAction";
import { UserNameReq } from "../../utils/Constant";

const CheckoutForm = (props: any) => {

    // Props
    const { saveUserResp, submitResp, setOpenpaymentModal, type,formikSection1 } = props;
    const navigate=useNavigate();

    // Hooks
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const location = useLocation();
    const userData = useSelector((state: any) => state.auth.authData);
    // console.log(invoiceList,"invoiceList")
    // console.log(TAX_RATE,"TAX_RATE")

    // States
    const [isHovered, setIsHovered] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);

    const fetchIsPopularData = async () => {
        try {
            await getIsPopularEventsLists({ search: "" }, dispatch);
        } catch (error) {
            console.error("Error fetching list data:", error);
        }
    };

    useEffect(() => {
        fetchInvoiceSettings();
    }, []);

    const fetchInvoiceSettings = async () => {
        // console.log("Fetching invoice settings...");
        try {
            const response = await InvoiceData({}, dispatch);
            //   console.log("Response from InvoiceData:", response);
            if (response?.data) {
                const { name, email, mobile, tax, terms, address, logo } = response.data;
                formikSection1.setFieldValue("name", name || "");
                formikSection1.setFieldValue("email", email || "");
                formikSection1.setFieldValue("address", address || "");
                formikSection1.setFieldValue("mobile", mobile || "");
                formikSection1.setFieldValue("tax", tax || "");
                formikSection1.setFieldValue("terms", terms || "");
                formikSection1.setFieldValue("logo", logo || "");
            }
        } catch (error) {
            console.error("Failed to fetch invoice settings:", error);
        }
    }
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitLoader(true);

        if (!elements || !stripe) {
            setSubmitLoader(false);
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            toast.error(submitError.message ?? "Something went wrong!");
            setSubmitLoader(false);
            return;
        }

        const paymentRes = await stripe.confirmPayment({
            elements,
            clientSecret: submitResp.clientSecret,
            confirmParams: { return_url: window.location.origin },
            redirect: "if_required",
        });

        if (paymentRes?.paymentIntent?.status === "succeeded") 
            {
            const res = await fetch(`${UrlHelper.serverApiUrl}registration/paymentResponse`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData?.token ?? ""}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: submitResp.clientSecret,
                    response: JSON.stringify(paymentRes),
                    payment_status: 2, // Success
                    type: type,
                }),
            });

            const { status, message } = await res.json();

            if (status) {
                const { id, data_id, ...newRes } = saveUserResp;
                setSubmitLoader(false);
                // dispatch(onSuccessfulAuth(newRes));
                dispatch(onPaymentAuth(newRes))
                toast.success(message ?? "Payment successful..!");
                setOpenpaymentModal(false)
                navigate( "/dashboard");
                if (location.pathname === "/dashboard") {
                    fetchIsPopularData()
                }
                setIsDisable(true)
            } else {
                toast.error(message ?? "Something went wrong !");
            }
        } else {
            const res = await fetch(`${UrlHelper.serverApiUrl}registration/paymentResponse`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userData?.token ?? ""}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: submitResp.clientSecret,
                    response: JSON.stringify(paymentRes),
                    payment_status: 3, // Failed
                    type: type,
                }),
            });
            setSubmitLoader(false);

            const { status, message } = await res.json();
            if (!status) {
                toast.error(message ?? "Something went wrong !");
            }
        }
        setSubmitLoader(false);
    };

    const handlePaymentElementChange = (event: any) => {
        setIsDisable(!event.complete);
    };
    return (
        <>
            <form onSubmit={handleSubmit} style={{ padding: "20px", borderRadius: "10px", border: '1px solid rgba(0, 0, 0, 0.5)' }} className="shadow">
                <PaymentElement onChange={handlePaymentElementChange} className="payment-element" />
                <div style={{ textAlign: "center" }}>
                    <button
                        color="primary"
                        type="submit"
                        className="customNextBtn"
                        style={{ padding: "19px", marginTop: "1rem" }}
                        disabled={isDisable || submitLoader || !stripe || !elements}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {submitLoader ? (
                            <div style={{ padding: "0rem 0.5rem" }}>
                                <Spinner
                                    size="sm"
                                    className={`spinner ${isHovered ? "hoveredSpinner" : ""}`}
                                />
                            </div>
                        ) : (
                            "Pay"
                        )}
                    </button>
                </div>
            </form>
        </>
    );
};

export default CheckoutForm;
