import { createSlice } from "@reduxjs/toolkit";

interface InitialFeedbackState {
    paymentList: any;
    paymentLogList: any;
    invoicePayment: any;
    rowCount: number;
    totalPayAmount:number
}

const InitialPaymentkState: InitialFeedbackState = {
    paymentList: [],
    paymentLogList: [],
    invoicePayment: [],
    rowCount: 0,
    totalPayAmount:0
};

const paymentSlice = createSlice({
    name: "payment",
    initialState: InitialPaymentkState,
    reducers: {
        paymentDataList(state: any, action: any) {
            state.paymentList = action.payload;
        },
        paymentLogDataList(state: any, action: any) {
            state.paymentLogList = action.payload;
        },
        invoicePaymentDataList(state: any, action: any) {
            state.invoicePayment = action.payload;
        },
        setPaymentlistCount(state: any, action: any) {
            state.rowCount = action.payload
        },
        refundPayment(state: any, action: { payload: { _id: string } }) {
            const { _id } = action.payload;

            state.paymentList = state.paymentList?.map((payment: any) =>
                payment._id === _id ? { ...payment, payment_status: 4 } : payment
            );
        },
        totalPayAmount(state:any,action){
            state.totalPayAmount=action.payload;

        }
    },
});

export const {
    paymentDataList,
    setPaymentlistCount,
    invoicePaymentDataList,
    refundPayment,
    paymentLogDataList,
    totalPayAmount
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const payment_List = (state: any) => state.payment.paymentList;

export const payment_log_List = (state: any) => state.payment.paymentLogList;

export const invoice_payment_List = (state: any) => state.payment.invoicePayment;

export const payment_Count = (state: any) => state.payment.rowCount;

export const TotalPayAmount=(state:any)=>state.payment.totalPayAmount;