import React from 'react';
import { IoClose } from "react-icons/io5";
import UrlHelper from '../../../../Helpers/Url';


interface Link {
  label: string;
  href: string;
}

const NavLinksModal = (props: { open: boolean; toggle: () => void }) => {
  const { open, toggle } = props;

  const links: Link[] = [
    { label: 'Home', href: `${UrlHelper.webServerUrl}/` },
    { label: 'WSF Foundation', href: `${UrlHelper.webServerUrl}/wsf-foundation/` },
    { label: 'Research & Advocacy', href: `${UrlHelper.webServerUrl}/research-advocacy/` },
  ];

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.querySelectorAll<HTMLHeadingElement>('h1').forEach((a) => {
      a.style.transform = 'translateY(-26px)';
      a.style.color = '#046bd2';
    });
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.querySelectorAll<HTMLHeadingElement>('h1').forEach((a) => {
      a.style.transform = 'translateY(0px)';
      a.style.color = '#1F2022';
    });
  };

  const navLinks = (link: Link, index: number) => {
    return (
      <div
        className="d-flex flex-column mb-2"
        style={{ height: '1.75rem', overflow: 'hidden' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        key={index}
      >
        <h1
          style={{
            fontSize:'20px',
            transition: 'all 0.3s ease-in-out',
            cursor:'pointer',
            fontWeight:600
          }}
        >
          {link.label}
        </h1>
        <h1
          style={{
            fontSize:'20px',
            transition: 'all 0.3s ease-in-out',
            cursor:'pointer',
            fontWeight:600
          }}
        >
          {link.label}
        </h1>
      </div>
    );
  };

  return (
    <>
     <div
        onClick={toggle}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          backdropFilter: open ? 'blur(5px)' : 'none',
          transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
          zIndex: open ? 999 : -1,
          pointerEvents: open ? 'auto' : 'none',
        }}
      ></div>


    <div
      style={{
        blockSize: '90%', 
        inlineSize: open ? '70%' : '0',
        position: 'fixed',
        insetBlockStart: 0,
        insetInlineEnd: open ? '0' : '-350px',
        backgroundColor: '#fff',
        boxShadow: open ? '-2px 0 10px rgba(0, 0, 0, 0.1)' : 'none',
        zIndex: 1200,
        transition: 'inset-inline-end 300ms ease-in-out, box-shadow 300ms ease-in-out',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '40px 0'
      }}
    >
      <div style={{ padding: '1rem',height:'-webkit-fill-available' }}>
        <div className='text-end'>
        <IoClose size={35} onClick={toggle} style={{cursor:'pointer'}}/>
            </div>
            <div style={{height:'calc(100% - 3rem)',display:'flex', flexDirection:'column', justifyContent:'center'}}>
        {Array.isArray(links) &&
          links?.length > 0 &&
          links?.map((link: Link, index: number) => navLinks(link, index))}
            </div>
      </div>
    </div>
    </>
  );
};

export default NavLinksModal;
