import './WFSFooter.scss'
import ImageHelper from "../../../../Helpers/Images/Image"
import { Collapse, Row, Col,Container } from "reactstrap";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
//react-icons imports
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { PiArrowCircleUpLight } from "react-icons/pi";

//css import
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

//helper imports
import UrlHelper from '../../../../Helpers/Url';
import ColorsHelper from '../../../../Helpers/Colors';

const WFSFooter = () => {
    const WSFBaseNavigationUrl = `${UrlHelper.webServerUrl}/`
    const [isCompanyOpen, setCompanyOpen] = useState(false);
    const [isQuickLinksOpen, setQuickLinksOpen] = useState(false);
    const [isAccreditationOpen, setAccreditationOpen] = useState(false);
    const [isLegalOpen, setLegalOpen] = useState(false);

    const toggleCompany = () => setCompanyOpen(!isCompanyOpen);
    const toggleQuickLinks = () => setQuickLinksOpen(!isQuickLinksOpen);
    const toggleAccreditation = () => setAccreditationOpen(!isAccreditationOpen);
    const toggleLegal = () => setLegalOpen(!isLegalOpen);
    const navigate=useNavigate();

    return (
        <>
            <Container fluid className='mt-5' style={{padding:'0 3rem',fontFamily:"Bricolage Grotesque"}}>
                <div className='d-none d-sm-block'>
                    <Row style={{ position: 'relative',rowGap:'4rem' }}>
                        <Col xs={12} lg={5}>
                            <div style={{ width: '100%', height: 'auto' }}>
                                <a href={`${UrlHelper.webServerUrl}`}>
                                <img
                                    src={ImageHelper.WSFLogo}
                                    alt={UrlHelper.serverPlatForm == "WSF" ? ImageHelper.WSFLogo : ImageHelper.ISSOLogoPlaceholder}
                                    width={385}
                                    height={96}
                                    style={{cursor:"pointer"}}
                                />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} lg={2}>
                            <div>
                                    <p className="Footer-Links-Title">Company</p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}about-us/`} className="text-decoration-none Footer-Navigation-Link">
                                            About Us
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}contact-us/`} className="text-decoration-none Footer-Navigation-Link">
                                            Contact Us
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}events-media/`} className="text-decoration-none Footer-Navigation-Link">
                                            Events & Media
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}faq/`} className="text-decoration-none Footer-Navigation-Link">
                                            FAQ
                                        </a>
                                    </p>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} lg={2}>
                            <div>
                                    <p className="Footer-Links-Title">Quick Links</p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}wsf-foundation/`} className="text-decoration-none Footer-Navigation-Link">
                                            WSF Foundation
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}member-community/`} className="text-decoration-none Footer-Navigation-Link">
                                            Member Community
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}marketing-alliance/`} className="text-decoration-none Footer-Navigation-Link">
                                            Marketing Alliance
                                        </a>
                                    </p>
                                    <p>
                                        <a href={`${WSFBaseNavigationUrl}#`} className="text-decoration-none Footer-Navigation-Link">
                                            Ethics Assist Line
                                        </a>
                                    </p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <div>
                                <p className="Footer-Links-Title">Accreditation</p>
                                <p>
                                    <a href={`${WSFBaseNavigationUrl}accreditations/schools-institutes/`} className="text-decoration-none Footer-Navigation-Link">
                                        Schools & Institutes
                                    </a>
                                </p>
                                <p>
                                    <a href={`${WSFBaseNavigationUrl}accreditations/students-and-learners/`} className="text-decoration-none Footer-Navigation-Link">
                                        Students and Learners
                                    </a>
                                </p>
                                <p>
                                    <a href={`${WSFBaseNavigationUrl}accreditations/skill-development-academies/`} className="text-decoration-none Footer-Navigation-Link">
                                        Skill Development Academies
                                    </a>
                                </p>
                                <p>
                                    <a href={`${WSFBaseNavigationUrl}accreditations/educators-curriculum-developers/`} className="text-decoration-none Footer-Navigation-Link">
                                        Educators & Curriculum Services
                                    </a>
                                </p>
                                <p>
                                    <a href={`${WSFBaseNavigationUrl}accreditations/education-games-competitions/`} className="text-decoration-none Footer-Navigation-Link">
                                        Product Gamification & Competition
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: '5%',
                                width: 'auto',
                                cursor: 'pointer',
                            }}
                        >
                            <a href="#">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    className="hover-svg"
                                >
                                    <circle
                                        cx="20"
                                        cy="20"
                                        r="19.5"
                                        stroke="#255DAB"
                                        fill="none"
                                        className="svg-circle"
                                    />
                                    <path
                                        d="M20 27L20 13M20 13L14 19M20 13L26 19"
                                        stroke="#255DAB"
                                        strokeWidth="1.5"
                                        className="svg-arrow"
                                    />
                                </svg>
                            </a>
                        </div>
                    </Row>
                    <div className="row align-items-center text-center text-md-start" style={{marginTop:'10rem',marginBottom:'3rem',fontFamily:"Montserrat"}}>

                        <div className="col-12 col-md-4 mb-2 mb-md-0 Footer-Copyright-Strip">
                            <h5><a href={`${WSFBaseNavigationUrl}policies-statement/`}  style={{cursor:"pointer",textDecoration:"none",color:"#585858"}}>Policies & Statement</a></h5>
                        </div>


                        <div className="col-12 col-md-5 mb-2 mb-md-0 Footer-Copyright-Strip">
                            <h5>World Stem Federation © 2024. All rights reserved.</h5>
                        </div>


                        <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-end gap-3">
                            <FaYoutube className="social-icon" />
                            <PiInstagramLogoFill className="social-icon" />
                            <FaFacebookF className="social-icon" />
                            <FaLinkedinIn className="social-icon" />
                        </div>
                    </div>
                </div>
            </Container>
            <div className='d-block d-sm-none'>
            <div className="bg-white text-center text-sm-start">
                <div className="container">
                    <div className="d-flex justify-content-between mb-3">
                        <div className='mb-5'>
                        <a href={`${UrlHelper.webServerUrl}`}>
                            <img
                                src={ImageHelper.WSFLogo}
                                alt="World STEM Federation"
                                className="footer-logo"
                                width={265}
                                height={66}
                            />
                            </a>
                        </div>
                        <div>
                            <a href="#">
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                className="hover-svg"
                            >
                                <circle
                                    cx="20"
                                    cy="20"
                                    r="19.5"
                                    stroke="#255DAB"
                                    fill="none"
                                    className="svg-circle"
                                />
                                <path
                                    d="M20 27L20 13M20 13L14 19M20 13L26 19"
                                    stroke="#255DAB"
                                    strokeWidth="1.5"
                                    className="svg-arrow"
                                />
                            </svg></a>
                        </div>
                    </div>
                        <div className="mb-3">
                            <div className="d-flex justify-content-between align-items-center fw-bold">
                                <p className='mb-1 Wsf-Mobile-View-Navigation-Heading' style={{fontFamily:"Bricolage Grotesque"}}>COMPANY</p>
                                <IoIosArrowDown onClick={toggleCompany} size={30} style={{ transition: 'all 0.2s ease-in-out', transform: isCompanyOpen ? 'rotate(180deg)' : 'none', cursor: 'pointer' }} />
                            </div>
                            <Collapse isOpen={isCompanyOpen}>
                                <div className='text-start' style={{fontFamily:"Bricolage Grotesque"}}>
                                    <p className='mb-1'>About Us</p>
                                    <p className='mb-1'>Contact Us</p>
                                    <p className='mb-1'>Events & Media</p>
                                    <p className='mb-1'>FAQ</p>
                                </div>
                            </Collapse>
                        </div>
                    <div className="mb-3">
                        <div
                            className="w-100 text-start d-flex justify-content-between align-items-center"
                        >
                            <p className='mb-1  Wsf-Mobile-View-Navigation-Heading' style={{fontFamily:"Bricolage Grotesque"}}>QUICK LINKS</p>
                            <IoIosArrowDown onClick={toggleQuickLinks} size={30} style={{ transition: 'all 0.2s ease-in-out', transform: isQuickLinksOpen ? 'rotate(180deg)' : 'none', cursor: 'pointer' }} />
                        </div>
                        <Collapse isOpen={isQuickLinksOpen}>
                            <div className="text-start" style={{fontFamily:"Bricolage Grotesque"}}>
                                <p className='mb-1' >WSF Foundation</p>
                                <p className='mb-1'>Member Community</p>
                                <p className='mb-1'>Marketing Alliance</p>
                                <p className='mb-1'>Ethics Assist Line</p>
                            </div>
                        </Collapse>
                    </div>
                    <div className="mb-3">
                        <div
                            className="w-100 text-start d-flex justify-content-between align-items-center"
                        >
                            <p className='mb-1  Wsf-Mobile-View-Navigation-Heading' style={{fontFamily:"Bricolage Grotesque"}}> ACCREDITATION</p>
                            <IoIosArrowDown onClick={toggleAccreditation} size={30} style={{ transition: 'all 0.2s ease-in-out', transform: isAccreditationOpen ? 'rotate(180deg)' : 'none', cursor: 'pointer' }} />
                        </div>
                        <Collapse isOpen={isAccreditationOpen}>
                            <div className="text-start" style={{fontFamily:"Bricolage Grotesque"}}>
                                <p className='mb-1'>Students and Learners</p>
                                <p className='mb-1'>Schools & Institutes</p>
                                <p className='mb-1'>Educators & Curriculum Developers</p>
                                <p className='mb-1'>Skill Development Academies</p>
                                <p className='mb-1'>Product Gamification & Competition</p>
                            </div>
                        </Collapse>
                    </div>
                    <div>
                        <div
                            className="w-100 text-start d-flex justify-content-between align-items-center"
                        >
                            <p className='mb-1 Wsf-Mobile-View-Navigation-Heading' style={{fontFamily:"Bricolage Grotesque"}}> LEGAL</p>
                            <IoIosArrowDown onClick={toggleLegal} size={30} style={{ transition: 'all 0.2s ease-in-out', transform: isLegalOpen ? 'rotate(180deg)' : 'none', cursor: 'pointer' }} />
                        </div>

                        </div>
                        <Collapse isOpen={isLegalOpen}>
                            <div className="text-start" style={{fontFamily:"Bricolage Grotesque"}}>
                                <p className='mb-1'>Privacy Policy</p>
                                <p className='mb-1'>Terms of Service</p>
                            </div>
                        </Collapse>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column ">
                        <div className="mt-5" style={{fontFamily:"Bricolage Grotesque"}}>
                            <p>
                                World Stem Federation © 2024. All Rights Reserved.
                            </p>
                        </div>
                        <div className="d-flex justify-content-center gap-3 mb-3 ">
                        <FaYoutube className="social-icon" />
                            <PiInstagramLogoFill className="social-icon" />
                            <FaFacebookF className="social-icon" />
                            <FaLinkedinIn className="social-icon" />
                        </div>
                    </div>



                </div>
            </div>
            <div className='strip'></div>
        </>
    )


    // return (
    //     <>
    //         <div>
    //             {!isMobile && <div style={{ float: "right" }}> <PiArrowCircleUpLight size={35} style={{ borderColor: "#255DAB" }} /></div>}
    //             {!isMobile && <div className="p-5 WSF-Footer-Container" style={{ ...AppUtils.getFontFamily() }}>
    //                 {/* <div className="tap-top" style={{ display:"block"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevrons-up"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg></div> */}
    //                 <div>
    //                     <div className="row ">
    //                         <div className="col-12 col-sm-6 col-md-12 col-lg-4 col-custom-5 d-flex justify-content-center justify-content-md-center mb-4 mb-md-5">
    //                             <img
    //                                 src={ImageHelper.WSFLogo}
    //                                 alt={UrlHelper.serverPlatForm == "WSF" ? ImageHelper.WSFLogo : ImageHelper.ISSOLogoPlaceholder}
    //                                 className="img-fluid WSF-Footer-Logo"
    //                                 height={96}
    //                                 width={330}
    //                             />
    //                         </div>
    //                         <div className="col-12 col-sm-6 col-md-4 col-lg-2 col-custom-2 mb-4 mb-md-0 d-flex justify-content-center">
    //                             <ul className="list-unstyled text-center text-md-start d-flex flex-column gap-3">
    //                                 <li className="fw-bold mb-2">Company</li>
    //                                 <li>
    //                                     <a href={`${WSFBaseNavigationUrl}about-us/`} className="text-decoration-none Footer-Navigation-Link">
    //                                         About Us
    //                                     </a>
    //                                 </li>
    //                                 <li>
    //                                     <a href={`${WSFBaseNavigationUrl}contact-us/`} className="text-decoration-none Footer-Navigation-Link">
    //                                         Contact Us
    //                                     </a>
    //                                 </li>
    //                                 <li>
    //                                     <a href={`${WSFBaseNavigationUrl}events-media/`} className="text-decoration-none Footer-Navigation-Link">
    //                                         Events & Media
    //                                     </a>
    //                                 </li>
    //                                 <li>
    //                                     <a href={`${WSFBaseNavigationUrl}faq/`} className="text-decoration-none Footer-Navigation-Link">
    //                                         FAQ
    //                                     </a>
    //                                 </li>
    //                             </ul>
    //                         </div>


    


    //                 </div>
   
    //             </div>}
    //             {isMobile && <><div className="bg-white text-center text-sm-start p-4">
    //                 <div className="container">
    //                     <div className="d-flex justify-content-between mb-3">
    //                         <div>
    //                             <img
    //                                 src={ImageHelper.WSFLogo}
    //                                 alt="World STEM Federation"
    //                                 className="footer-logo"
    //                                 width={265}
    //                                 height={66}
    //                             />
    //                         </div>
    //                         <div>
    //                             <PiArrowCircleUpLight size={35} style={{ borderColor: "#255DAB" }} />
    //                         </div>
    //                     </div>
    //                     <div className="mb-2">
    //                         <div
    //                             className="w-100 text-start d-flex justify-content-between align-items-center"
    //                             onClick={toggleCompany}
    //                             style={{ backgroundColor: "none" }}
    //                         >
    //                             COMPANY
    //                             <span>{!isCompanyOpen ? <IoIosArrowDown size={30} /> : <IoIosArrowUp size={30} />}</span>
    //                         </div>
    //                         <Collapse isOpen={isCompanyOpen}>
    //                             <ul className="list-unstyled ps-3">
    //                                 <li>About Us</li>
    //                                 <li>Contact Us</li>
    //                                 <li>Events & Media</li>
    //                                 <li>FAQ</li>
    //                             </ul>
    //                         </Collapse>
    //                     </div>
    //                     <div className="mb-2">
    //                         <div
    //                             className="w-100 text-start d-flex justify-content-between align-items-center"
    //                             onClick={toggleQuickLinks}
    //                         >
    //                             QUICK LINKS
    //                             <span>{!isQuickLinksOpen ? <IoIosArrowDown size={30} /> : <IoIosArrowUp size={30} />}</span>
    //                         </div>
    //                         <Collapse isOpen={isQuickLinksOpen}>
    //                             <ul className="list-unstyled ps-3">
    //                                 <li>WSF Foundation</li>
    //                                 <li>Member Community</li>
    //                                 <li>Marketing Alliance</li>
    //                                 <li>Ethics Assist Line</li>
    //                             </ul>
    //                         </Collapse>
    //                     </div>
    //                     <div className="mb-2">
    //                         <div
    //                             className="w-100 text-start d-flex justify-content-between align-items-center"
    //                             onClick={toggleAccreditation}
    //                         >
    //                             ACCREDITATION
    //                             <span>{!isAccreditationOpen ? <IoIosArrowDown size={30} /> : <IoIosArrowUp size={30} />}</span>
    //                         </div>
    //                         <Collapse isOpen={isAccreditationOpen}>
    //                             <ul className="list-unstyled ps-3">
    //                                 <li>Schools & Institutes</li>
    //                                 <li>Students and Learners</li>
    //                                 <li>Skill Development Academies</li>
    //                                 <li>Educators & Curriculum Developers</li>
    //                                 <li>Product Gamification & Competition</li>
    //                             </ul>
    //                         </Collapse>
    //                     </div>
    //                     <div className="mb-2">
    //                         <div
    //                             className="w-100 text-start d-flex justify-content-between align-items-center"
    //                             onClick={toggleLegal}
    //                         >
    //                             LEGAL
    //                             <span>{!isLegalOpen ? <IoIosArrowDown size={30} /> : <IoIosArrowUp size={30} />}</span>
    //                         </div>
    //                         <Collapse isOpen={isLegalOpen}>
    //                             <ul className="list-unstyled ps-3">
    //                                 <li>Privacy Policy</li>
    //                                 <li>Terms of Service</li>
    //                             </ul>
    //                         </Collapse>
    //                     </div>
    //                     <div className="d-flex justify-content-center align-items-center gap-2 flex-column ">
    //                         <div className="mt-3">
    //                             <p className="small">
    //                                 World Stem Federation © 2024. All Rights Reserved.
    //                             </p>
    //                         </div>
    //                         <div className="d-flex justify-content-center gap-3 ">
    //                             <a href="https://youtube.com" target="_blank" rel="noreferrer">
    //                                 <FaYoutube className="social-icon" />

    //                             </a>
    //                             <a href="https://instagram.com" target="_blank" rel="noreferrer">
    //                                 <PiInstagramLogoFill className="social-icon" />

    //                             </a>
    //                             <a href="https://facebook.com" target="_blank" rel="noreferrer">
    //                                 <FaFacebookF className="social-icon" />

    //                             </a>
    //                             <a href="https://linkedin.com" target="_blank" rel="noreferrer">
    //                                 <FaLinkedinIn className="social-icon" />
    //                             </a>
    //                         </div>
    //                     </div>



    //                 </div>
    //             </div></>}

    //         </div>

    //     </>

    // )
}
export default WFSFooter