import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  users: any[];
  rowCount: number;
  usersByCat: any;
}
const initialUsersState: InitialUsersState = {
  users: [],
  usersByCat: [],
  rowCount: 0,
}

const ReportuserSlice = createSlice({
  name: "reportUsers",
  initialState: initialUsersState,
  reducers: {
    fetchUsers(state: any, action: any) {
      state.users = action.payload;
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    setuserByStatus(state: any, action: any) {
      state.users = action.payload;
    },
  }
}
)
export const {
  fetchUsers,
  setRowCount
} = ReportuserSlice.actions;

export default ReportuserSlice.reducer;

export const rowCount = (state: any) => state.reportUsers.rowCount;
export const userData = (state: any) => state.reportUsers.users;
export const usersByStatus = (state: any) => state.reportUsers.users;