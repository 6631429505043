//Create By Raj.
// React
import { useLocation, useNavigate } from "react-router-dom";

// ** Bootstrap Imports
import {
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

// ** Third Party 
import { H2 } from "../../../AbstractElements";

// ** Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// ** Redux
import { useDispatch, useSelector } from "react-redux";
import { addTestimonialData } from "../../../ReduxToolkit/Reducer/Testimonial/TestimonialAction";

// Helper
import Consthelper from "../../../Helpers/Constants";
import AppUtils from "../../../Helpers/AppUtils";
import { longDescValidation, shortDescValidation } from "../../../utils/Constant";

// ** Style
import "./Footer.scss"
import { authData } from "../../../ReduxToolkit/Reducer/Auth/AuthSlice";
import { capitalizeFirstLetter } from "../../../Component/CustomLetters";


interface TestimonialData {
    id: string;
    short_description: string;
    long_description: string;
}

const defaultValues: TestimonialData = {
    id: "",
    short_description: "",
    long_description: ""
};

export default function Testimonialpopup(props: any) {
    //Props
    const { isTestimonialVisible, toggleModal } = props;

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.pathname
    const userType = useSelector(authData);

    //Formik Validation
    const schema = Yup.object().shape({
        short_description: Yup.string().required(shortDescValidation),
        long_description: Yup.string().required(longDescValidation),
    });

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: schema,
        onSubmit: async (values: TestimonialData) => {
            const passData: any = {
                // id: subscribeData,
                short_description: values.short_description,
                long_description: values.long_description,
                user_type: userType?.data?.user_type,
                first_name: userType?.data?.first_name,
                last_name: userType?.data?.last_name,
            };
            const res = await addTestimonialData(passData, dispatch);
            if (res?.status && AppUtils.getLocalStorage("ISSO")) {
                formik.resetForm();
                toggleModal()
            } else {
                AppUtils.setLocalStorage("previousPage", previousPage);
                navigate("/login");
            }
        }
    });

    return (
        <Modal isOpen={isTestimonialVisible} centered id="testimonial">
            <ModalHeader toggle={toggleModal}>
                <H2>Testimonial</H2>
            </ModalHeader>
            <ModalBody className="sub-modalbody">
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <div>
                        <Label>Short Description</Label>
                        <Input
                            type="text"
                            id={"short_description"}
                            name={"short_description"}
                            placeholder={"Enter Short Description"}
                            value={formik.values.short_description}
                            style={{
                                padding: "0.675rem 0.75rem"
                            }}
                            onChange={(e) => {
                                const formatlatters=capitalizeFirstLetter(e.target.value)
                                formik.setFieldValue("short_description",formatlatters)
                            }}
                        />
                        {formik.touched.short_description && formik.errors.short_description ? (
                            <div className="text-danger" style={{ marginTop: "0.3rem", fontSize: "0.8rem" }}>
                                {formik.touched.short_description &&
                                    formik.errors.short_description}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <Label>Description</Label>
                        <Input
                            type="textarea"
                            id={"long_description"}
                            name={"long_description"}
                            placeholder={"Enter Long Description"}
                            value={formik.values.long_description}
                            style={{
                                width: "100%",
                                height: "200px",
                                padding: "0.675rem 0.75rem",
                                resize: "vertical"
                            }}
                            onChange={(e) => {
                                const formatlatters=capitalizeFirstLetter(e.target.value)
                                formik.setFieldValue("long_description",formatlatters)
                            }}
                        />
                        {formik.touched.long_description && formik.errors.long_description ? (
                            <div className="text-danger" style={{ marginTop: "0.3rem", fontSize: "0.8rem" }}>
                                {formik.touched.long_description &&
                                    formik.errors.long_description}
                            </div>
                        ) : null}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center", gap: "2rem" }}>
                <button className="sub-cancel" onClick={toggleModal}>
                    Cancel
                </button>
                <button className="submithoverbtn" onClick={() => formik.handleSubmit()}>
                    Submit
                </button>
            </ModalFooter>
        </Modal >
    );
}
