// Created By Raj.

// ** Bootstrap Imports
import { Row, Col, Input, Spinner, Label, Collapse, Modal, ModalHeader, ModalBody } from "reactstrap";

// ** Third Party
import { Btn } from "../../../AbstractElements";

// **Helpers 
import UrlHelper from "../../../Helpers/Url";
import ImageHelper from "../../../Helpers/Images/Image";

// ** Redux
import { useSelector } from "react-redux";
import { couponData } from "../../../ReduxToolkit/Reducer/FormBuilder/formbuilderSlice";

// ** Style
import formbuilderStyle from "./FormBuilder.style";
import "./FormBuilder.scss";
import { earingRemdeempoints } from "../../../ReduxToolkit/Reducer/MyEarnings/MyEarningsSlice";
import { authData } from "../../../ReduxToolkit/Reducer/Auth/AuthSlice";
import { fetchUserPoints } from "../../../ReduxToolkit/Reducer/MyEarnings/MyEarningsAction";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { InvoiceData } from "../../../ReduxToolkit/Reducer/InvoiceConfig/InvoiceAction";
import { InVoice_list } from "../../../ReduxToolkit/Reducer/InvoiceConfig/InvoiceSlice";
import { totalPayAmount } from "../../../ReduxToolkit/Reducer/Payment/PaymentSlice";
import { new_user_data } from "../../../ReduxToolkit/Reducer/Category/categorySlice";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PaymentGetWay from "../../../CommonElements/EventsPaymentGetway/PaymentGetway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { registerUser } from "../../../ReduxToolkit/Reducer/Regisrtation/RegistrationAction";
import AppUtils from "../../../Helpers/AppUtils";

const POINTS_PER_DOLLAR = 100; // 100 points equal 1 dollar




const CustomViewBenefits = (props: any) => {
  //Props
  const { activeSection, setActiveSection,formikSection1, formik, loader, isAuthView, eventInfo, eventType, userData,couponFormik, userID } = props;


  // hooks
  const {fee}=useParams();
  const couponRes = useSelector(couponData)
  const remainingdata=useSelector(earingRemdeempoints)
  const user= useSelector(authData);
  const dispatch = useDispatch();
  const invoiceList = useSelector(InVoice_list);
  const navigate=useNavigate();
  const location = useLocation();
  const receivedData = location.state;
  const[openpaymentModal,setOpenpaymentModal]=useState(false); 
  const [saveUserResp, setSaveUserResp] = useState<any>([]);
  const [submitResp, setSubmitResp] = useState<any>([]);
  const stripePromise = loadStripe("pk_test_z6IPyiUXb5ZzpeK3mWUJAAQF");
  const options: any = {
    mode: "payment",
    amount: receivedData?.RegistrationFee ? receivedData?.RegistrationFee * 100 : 0,
    currency: "usd",
    // automatic_payment_methods: {
    //     enabled: true,
    // },
    // Fully customizable with appearance API.
    appearance: {
        /*...*/
    },
};
console.log("id",receivedData.id)
console.log("free",receivedData.is_free);


let TAX_RATE = invoiceList.tax
// console.log(TAX_RATE,"TAX_RATE")

  function formatPriceWithoutSymbol(price: number) {
    let formattedPrice = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(price);
    formattedPrice = formattedPrice.substring(1).replace(/(\.00)$/, "");

    return formattedPrice;
  }

  useEffect(() => {
    if (user) {
        fetchUserDetails();
    };
}, [user]);

const fetchUserDetails = async () => {
    try {
        const res = await fetchUserPoints({ id: user.data.id }, dispatch);
    } catch (error) {
        console.error("Error fetching list data:", error);
    }
};


  useEffect(() => {
    fetchInvoiceSettings();
  }, []);

  const fetchInvoiceSettings = async () => {
    console.log("Fetching invoice settings...");
    try {
      const response = await InvoiceData({}, dispatch);
      console.log("Response from InvoiceData:", response);
      if (response?.data) {
        const { name, email, mobile, tax, terms, address, logo } = response.data;
        formik.setFieldValue("name", name || "");
        formik.setFieldValue("email", email || "");
        formik.setFieldValue("address", address || "");
        formik.setFieldValue("mobile", AppUtils.mobileNumberFormate(mobile) ?? "");
        formik.setFieldValue("tax", tax || "");
        formik.setFieldValue("terms", terms || "");
        formik.setFieldValue("logo", logo || "");
      }
    } catch (error) {
      console.error("Failed to fetch invoice settings:", error);
    }
  }

  // function applyPointsWithoutDiscount(price: number, points: number) {
  //   const pointsNeeded = price * POINTS_PER_DOLLAR;
  //   const pointsToApply = Math.min(points, pointsNeeded);
  //   const amountReducedByPoints = pointsToApply / POINTS_PER_DOLLAR;
  //   const amountToPay = price - amountReducedByPoints;

  //   return {
  //     finalPrice: amountToPay,
  //     pointsUsed: pointsToApply,
  //     amountReduced: amountReducedByPoints,
  //   };
  // }

    const [isOpen, setIsOpen] = useState(false);
  const toggleSwitch = () => setIsOpen(!isOpen);

  // const calculateTotalAmount = () => {
  //   let paid_amount = submitResp?.planList?.price;
  //   let taxAmount:any = 0; // To store calculated tax
  //   let points_used: any = 0;
  //   let pointsAmountAdd: any = 0;

  //   if (isOpen) {
  //     // Apply points if the switch is ON
  //     if (remainingdata?.pointsRemaining > 0) {
  //       points_used = applyPointsWithoutDiscount(paid_amount, remainingdata.pointsRemaining);

  //       // After applying points, ensure the amount doesn't drop below zero
  //       paid_amount = Math.max(points_used.finalPrice, 0);
  //       pointsAmountAdd = points_used?.amountReduced;
  //     }
  //     taxAmount = applyTax(paid_amount, TAX_RATE);
  //     // Set taxAmount to 0 when the switch is ON (no tax)
  //   } else {
  //     // If the switch is OFF, apply normal tax without points
  //     taxAmount = applyTax(paid_amount, TAX_RATE);
  //   }

  //   if (couponRes && couponRes?.coupon_code?.length > 0) {
  //     paid_amount = discountedPrice(paid_amount, couponRes?.amount, couponRes?.amount_type)
  //     taxAmount = applyTax(paid_amount, TAX_RATE);
  //   }

  //   // Calculate the total amount after applying tax (if any)
  //   const totalAmount = paid_amount + taxAmount;
  //   dispatch(totalPayAmount(totalAmount));

  //   return {
  //     finalPrice: paid_amount?.toFixed(2),
  //     taxAmount: taxAmount?.toFixed(2),
  //     pointsUsed: points_used?.pointsUsed || 0,
  //     totalPriceAfterTax: totalAmount.toFixed(2),
  //     pointsApplied: points_used?.pointsUsed || 0,
  //     pointsAmountAdd: pointsAmountAdd.toFixed(2),
  //   };
  // };
  const userRegistered = async (e:any) => {
    e.preventDefault();
    const passData: any = {
      amount: receivedData.amount,
      currency: "usd",
      id: receivedData.id,
      is_free: receivedData.is_free,
      type: receivedData.type,// 2.Event, 3.Seminar, 4.Workshop
    };

    if (isOpen) {
      passData.apply_points = true
    }

    const res = await registerUser(passData);
    if (res?.status) {
        setSubmitResp(res)
        setSaveUserResp(res)
        if(totalPriceAfterTax>0){
          setOpenpaymentModal(true)

        }else{
          navigate("/dashboard")
        }

        
        // dispatch(
        //     updateSPEvents({ _id: selectedEvent?._id, is_registered: true })
        // );
        // if (selectedEvent?.is_free) {
        //     setOpenModel2(false);
        //     setOpenpaymentModal(false);
        //     fetchEveDetails();
        // } else {
        //     setOpenpaymentModal(true)
        //     setOpenModelPay(false);
        // }
    }
};
  const calculateTotalAmount = () => {
    let paid_amount = receivedData.RegistrationFee;
    let taxAmount = 0; // To store calculated tax
    let points_used: any=0;
    let pointsAmountAdd: any = 0;

    if (isOpen) {
        // Apply points if the switch is ON
        if (remainingdata?.pointsRemaining > 0) {
            points_used = applyPointsWithoutDiscount(paid_amount, remainingdata.pointsRemaining);

            // After applying points, ensure the amount doesn't drop below zero
            paid_amount = Math.max(points_used.finalPrice, 0);
            pointsAmountAdd = points_used?.amountReduced;
        }
        if(paid_amount>0){
          taxAmount = applyTax(paid_amount, TAX_RATE);

        }
        // Set taxAmount to 0 when the switch is ON (no tax)
    } else {
        // If the switch is OFF, apply normal tax without points
        taxAmount = applyTax(paid_amount, TAX_RATE);
    }

    // Calculate the total amount after applying tax (if any)
    const totalAmount = paid_amount + taxAmount;
    dispatch(totalPayAmount(totalAmount));

    return {
        finalPrice: paid_amount,
        taxAmount: taxAmount,
        pointsUsed: points_used?.pointsUsed || 0,
        totalPriceAfterTax: totalAmount,
        pointsApplied: points_used?.pointsUsed || 0,
        pointsAmountAdd: pointsAmountAdd,
    };
};

function applyPointsWithoutDiscount(price: number, points: number) {
    const pointsNeeded = price * POINTS_PER_DOLLAR;
    const pointsToApply = Math.min(points, pointsNeeded);
    const amountReducedByPoints = pointsToApply / POINTS_PER_DOLLAR;
    const amountToPay = price - amountReducedByPoints;
    console.log(amountToPay);

    return {
        finalPrice: amountToPay,
        pointsUsed: pointsToApply,
        amountReduced: amountReducedByPoints,
    };
}

const applyTax = (price: number, taxRate: number) => {
    return price * (taxRate / 100);
};
  

  // function discountedPrice(price: number, amount: number, amount_type: number) {
  //   let discountAmount = amount;


  //   if (amount_type === 1) {
  //     const discountPercentage = amount;
  //     discountAmount = (price * discountPercentage) / 100;
  //   } else if (amount_type === 2) {
  //     const discountPrice = amount;
  //     discountAmount = discountPrice;
  //   }

  //   return price - discountAmount;
  // }

//   function applyPointsToPayment(price: number, points: number): { finalPrice: number; discountFromPoints: number; pointsUsed: number } {
//     const maxPointsCanUse = price * POINTS_PER_DOLLAR; 
//     const pointsToUse = Math.min(points, maxPointsCanUse); 
//     const discountFromPoints = pointsToUse / POINTS_PER_DOLLAR; 
//     const finalPrice = price - discountFromPoints; 

//     return {
//         finalPrice: finalPrice > 0 ? finalPrice : 0,
//         discountFromPoints: discountFromPoints,
//         pointsUsed: pointsToUse 
//     };
// }

  // const applyTax = (price:number, taxRate:number) => {
  //   const taxAmount = price * (taxRate / 100);
  //   return taxAmount;
  // };

  

  const getDurationDate = (value: any, currentDate: Date): string => {
    let formattedDate = "";

    switch (value) {
      case 1:
        formattedDate = formatDateRange(currentDate, 1);
        break;
      case 2:
        formattedDate = formatDateRange(currentDate, 3);
        break;
      case 3:
        formattedDate = formatDateRange(currentDate, 12);
        break;
      default:
        formattedDate = "";
        break;
    }

    return `${formattedDate}`;
  };

  const formatDateRange = (startDate: Date, durationInMonths: number): string => {
    const endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + durationInMonths);

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const getDurationLabel = (value: any) => {
    switch (value) {
      case 1:
        return "Monthly".substring(0, 1) + "o";
      case 2:
        return "Quarterly".substring(0, 1) + "tr";
      case 3:
        return "Yearly".substring(0, 1) + "r";
      case 4:
        return "One Time"
      default:
        return "";
    }
  };

  // Function to format date
  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day} ${getMonthName(date.getMonth())} ${year}`;
  };

  // Function to get month name
  const getMonthName = (month: number): string => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[month];
  };

 

  const getImageUrl = (image: any) => {
    if (Array.isArray(image) && image.length > 0) {
      // If image is an array, return the first image
      return `${UrlHelper.serverImageUrl}${image[0].file_path}`;
    } else if (typeof image === 'string' && image) {
      // If image is a string and not empty, return the image URL
      return `${UrlHelper.serverImageUrl}${image}`;
    } else {
      // Return a default image if no image is provided
      // return {UrlHelper.serverPlatForm=="WSF" ? ImageHelper.WSFLogo :ImageHelper.ISSOLogoPlaceholder};
    }
  };

  // const currentDate = new Date;

  const { taxAmount, pointsUsed, totalPriceAfterTax, pointsApplied, pointsAmountAdd,finalPrice } = calculateTotalAmount();

  // const finalShowPrice = () => {
  //   if(isOpen || couponRes?.coupon_code?.length > 0){
  //     return <><del>${submitResp?.planList?.price}/{getDurationLabel(submitResp?.planList?.duration)}</del> &nbsp;${finalPrice}/{getDurationLabel(submitResp?.planList?.duration)}</>
  //   } else {
  //     return `$${submitResp?.planList?.price}/${getDurationLabel(submitResp?.planList?.duration)}`
  //   }
  // }

  return (
    <>
      <Row className="justify-content-center" style={{ margin: "6rem 0rem", gap: "3rem", padding: "0rem 2rem" }}>
        <Col sm={8} md={8} lg={4} style={{ display: "flex", flexDirection: 'column', gap: "2rem" }}>
          
          {/* User Information Section */}
          <div>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>User Information</p>
            <div>
              <p style={formbuilderStyle?.fullname}>Full Name</p>
              <p style={formbuilderStyle.username}>
                {user.data.first_name} {user.data.last_name}
              </p>
            </div>
            <div>
              <p style={formbuilderStyle?.fullname}>Email</p>
              <p style={formbuilderStyle.username}>
              {user.data.email}
              </p>
            </div>
            <div>
              <p style={formbuilderStyle?.fullname}>Phone Number</p>
              <p style={{ ...formbuilderStyle.username }}>
                {user.data.mobile}
              </p>
            </div>
            <div>
              <p style={formbuilderStyle?.fullname}>Remaining Points</p>
              <p style={{ ...formbuilderStyle.username, marginBottom: "0px" }}>
                {remainingdata.pointsRemaining}
              </p>
            </div>
          </div>
  
          {/* Coupon Section
          {receivedData.submitResp?.is_coupon && (
            <Col sm={6} md={4} style={{ width: "100%", position: "relative" }}>
              <Label style={{ ...formbuilderStyle?.fullname, marginBottom: "0.2rem" }}>Enter Coupon Code</Label>
              <Input
                type="text"
                placeholder="Enter Coupon Code"
                name="coupon_code"
                value={formik.values.coupon_code}
                style={formbuilderStyle.couponInput}
                onChange={formik.handleChange}
              />
              {formik.touched.coupon_code && formik.errors.coupon_code && (
                <div className="text-danger">{formik.errors.coupon_code}</div>
              )}
              <Btn
                type="submit"
                color="primary"
                style={{
                  ...formbuilderStyle.couponCodeBtn,
                  position: "absolute",
                  top: "35px",
                  right: "7px",
                }}
                onClick={formik.handleSubmit}
                disabled={loader}
              >
                {loader ? <Spinner size="sm" color="light" /> : "Apply Coupon"}
              </Btn>
            </Col>
          )} */}
  
          {/* Price Breakdown Section vivekbusa */}
          <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
              Payment Details
            </p>
            {/* <div className="d-flex justify-content-between">
              <p style={formbuilderStyle.membershipDe}> Total Points ({remainingdata.pointsRemaining})</p>
              <Input type="switch" checked={isOpen} onChange={toggleSwitch} role="switch" disabled={Boolean(remainingdata.pointsRemaining === 0)} />
            </div>
            <Collapse isOpen={isOpen}>
              <div className="d-flex justify-content-between">
                <p style={formbuilderStyle.membershipDe}>Use Points ({pointsApplied})</p>
                <p style={formbuilderStyle.membershipPlan}>${pointsAmountAdd}</p>
              </div>
            </Collapse> */}
             {remainingdata.pointsRemaining>0 && 
              <>
                <div className="d-flex justify-content-between">
                  <p style={formbuilderStyle.membershipDe}> Total Points ({remainingdata.pointsRemaining})</p>
                  <div className="form-check-size">
                    <p style={formbuilderStyle.membershipDe}>Use Point</p>
                    <div className="form-check form-switch form-check-inline">
                      <Input className="check-size" id="flexSwitchCheckDefault2" type="checkbox" checked={isOpen} onChange={toggleSwitch} disabled={Boolean(remainingdata.pointsRemaining === 0)}  />
                    </div>
                  </div>
                </div>
                <Collapse isOpen={isOpen}>
                  <div className="d-flex justify-content-between">
                    <p style={formbuilderStyle.membershipDe}>Use Points ({pointsApplied})</p>
                    <p style={formbuilderStyle.membershipPlan}>${pointsAmountAdd}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={formbuilderStyle.membershipDe}>Balance Points ({remainingdata.pointsRemaining - pointsApplied})</p>
                    <p style={formbuilderStyle.membershipPlan}>${(remainingdata.pointsRemaining - pointsApplied) / POINTS_PER_DOLLAR}</p>
                  </div>
                </Collapse>
              </>
            }
            <div className="d-flex justify-content-between">
              <p style={formbuilderStyle.membershipDe}>
                {isAuthView ? 'Registration' : 'Plan'} amount
              </p>
              <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
                {isOpen && <del style={formbuilderStyle.membershipPlan}>
                  ${receivedData.RegistrationFee}
                </del>}
                <p style={formbuilderStyle.membershipPlan}>
                  ${!isOpen ? receivedData.RegistrationFee : finalPrice}
                </p> 
              </div>
            </div>
  
            {/* Discount Section */}
            {!isAuthView && (
              <>
              {/* Tax Section */}
                <div className="d-flex justify-content-between">
                  <p style={formbuilderStyle.membershipDe}>Tax ({totalPriceAfterTax>0 ?TAX_RATE:0}%)</p>
                  <p style={formbuilderStyle.membershipPlan}>
                    ${totalPriceAfterTax>0 ? formatPriceWithoutSymbol(taxAmount):0}
                  </p>
                </div>
                <hr />
  
                {/* Total Amount */}
                <div className="d-flex justify-content-between">
                  <p style={formbuilderStyle.membershipDe}>Total Amount</p>
                  <p style={formbuilderStyle.membershipPlan}>${totalPriceAfterTax>0 ? totalPriceAfterTax : 0}</p>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col sm={8} md={8} lg={4} style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <div style={{ height: "100%" }}>
            <div className="benifit-bgOne">
              <div style={{ position: "absolute", top: "0px", right: "0px" }}>
                <img src={ImageHelper.BenefitsRight} alt="check icon" style={{ marginTop: "7px" }} />
              </div>
              <p style={{ fontWeight: "600", marginBottom: "0px", fontSize: "18px" }}>
              {receivedData.EventTitle}
              </p>
              <div className={isAuthView ? "subBenefitsBox" : "benefitsBox"}>
                <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                  {isAuthView ?
                    <div style={formbuilderStyle.imgDiv}>
                      <img
                        src={getImageUrl(eventInfo.images || eventInfo.image)}
                        alt="sport"
                        style={{ width: "55px", height: "55px", borderRadius: "8px" }}
                      />
                    </div>
                    :
                    <div style={{ ...formbuilderStyle.imgDiv, padding: "10px" }} >
                      <img src={ImageHelper.paymentBlack} alt="pay" />
                    </div>
                  }
                  <div style={{ width: "100%" }}>
                    <p className="benifitsName">
                      {isAuthView ? eventInfo?.title || eventInfo?.name : submitResp?.planList?.plan_name}
                    </p>
                    <p style={formbuilderStyle.displayPrice}>
  ${!isOpen ? receivedData.RegistrationFee : totalPriceAfterTax}
</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="benifit-bgThree" style={{ padding: isAuthView ? "40px" : "18px" }} >
              <div style={{ marginTop: "45px" }}>
                {isAuthView ?
                  <div className="mt-3">
                    <div style={formbuilderStyle.benifetsDes}>
                      <p className="mb-0" style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 5,
                        overflow: "hidden"
                      }}>{eventInfo?.description}</p>
                    </div>
                  </div>
                  :
                  <div className="mt-3">
                    <p className="benifetsTitle">{receivedData.EventDescription}</p>
                    {submitResp?.planList?.benefits?.map(
                      (benefit: any, i: number) => (
                        <div key={i} style={{ display: "flex", alignItems: "start", gap: "0.5rem", marginBottom: "1rem" }}>
                          <img src={ImageHelper.Checkbenefits} alt="check icon" style={{ marginTop: "7px" }} />
                          <p className="mb-0">{benefit}</p>
                        </div>
                      ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ margin: "6rem 0rem" }}>
        <Col xs={12} sm={12} md={5}>
          {/* Footer Buttons  */}
          <div style={{ height: "50px", flexShrink: 0 }}>
            <div
              className="d-flex justify-content-center align-items-center mt-4"
              style={{ gap: "3rem" }}
            >
              <button
                style={{ padding: "17px", color: "#3853a3" }}
                className="cancelBtn"
                onClick={(e) => {
                  navigate(-1);
                }}
              >
                Previous
              </button>
               <button
                type="submit"
                className="customNextBtn"
                style={{ padding: "19px" }}
                onClick={(e)=>userRegistered(e)}
                >
                  Register
                </button>
            </div>
          </div>
        </Col>
        <Modal
                isOpen={openpaymentModal}
                toggle={() => setOpenpaymentModal(!openpaymentModal)}
                centered
                keyboard={false}
                backdrop={false}
            >
                <ModalHeader toggle={() => setOpenpaymentModal(!openpaymentModal)}>
                    Event Payment
                </ModalHeader>
                <ModalBody>
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentGetWay
                         setOpenpaymentModal={setOpenpaymentModal}
                            saveUserResp={saveUserResp}
                            submitResp={submitResp}
                            type={receivedData.type}
                        />
                    </Elements>
                </ModalBody>
            </Modal>
      </Row>
    </>
  );
};

export default CustomViewBenefits;
